import React from 'react'

import './keyboard.css'


const qwertyKeys = [["Q","W","E","R","T","Y","U","I","O","P"],["A","S","D","F","G","H","J","K","L","↲"],["Z","X","C","V","B","N","M","⌫"]]
const azertyKeys = [["A","Z","E","R","T","Y","U","I","O","P"],["Q","S","D","F","G","H","J","K","L","M"],["W","X","C","V","B","N","⌫", '↲']]

export default function Keyboard({keyboard, getLetter, chosen, colors}) {
    const {correct, incorrect, misplaced} = chosen

    const evaluationKeyStyle = (key) => {
        if(correct.includes(key)) return colors.good
        if(incorrect.includes(key)) return colors.std
        if(misplaced.includes(key)) return colors.misplaced
        return ''
    } 
    return (
        <div id="keyboard">
            {keyboard === 'qwerty' &&
            qwertyKeys.map((row,idx) => {
                return (
                    <div key={idx} className="row">
                        {row.map((key,idxl) => <div key={"key-" + idxl} style={{backgroundColor : evaluationKeyStyle(key)}} className={`${key === '↲' ? 'key enter-key' : 'key'}`} onClick={getLetter}>{key}</div>)}
                    </div>  
                )
            })}
            {keyboard === 'azerty' &&
            azertyKeys.map((row,idx) => {
                return (
                    <div key={idx} className="row">
                        {row.map((key,idxl) => <div key={"key-" + idxl} style={{backgroundColor : evaluationKeyStyle(key)}} className={`${key === '↲' ? 'key enter-key' : 'key'}`} onClick={getLetter}>{key}</div>)}
                    </div>  
                )
            })}
        </div>
    )
}
