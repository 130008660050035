import React from 'react'
import './notFound.scss'
import motuzLogo from '../../../../images/motuz-logo.png'

const navigateToHome = () => {
    window.location.href = `https://motuz.fr`
}

export default function NotFound() {
    return (
        <div className='not-found'>
            <div className='container'>
                <p>Game Not Found</p>
                <div className="motuz-title" onClick={navigateToHome}>
                    <img src={motuzLogo} alt='motuz logo' title='Go to MOTUZ website'/>
                </div>
            </div>
        </div>
    )
}
