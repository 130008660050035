import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'

import Body from './components/body/Body';

import './App.css';

import { fab } from '@fortawesome/free-brands-svg-icons'
import { faRotate, faExclamationCircle, faEllipsis , faCashRegister, faCloudArrowUp, faCalendarDays, faChartSimple, faQuestion, faGear, faArrowTurnDown, faCircleXmark, faInfo, faShareNodes, faVolumeHigh, faVolumeXmark, faCirclePlus, faArrowUpFromBracket, faPenToSquare, faTrash, faXmark} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faRotate, faExclamationCircle, faEllipsis, faCashRegister, faCloudArrowUp, faCalendarDays, faChartSimple, faQuestion, faGear, faArrowTurnDown, faCircleXmark, faInfo, faShareNodes, faVolumeHigh, faVolumeXmark, faCirclePlus, faArrowUpFromBracket, faPenToSquare, faTrash, faXmark)

function App() {
  return (
    <Router>
      <div className="App">
        <Body />
      </div>
    </Router>
  );
}

export default App;
