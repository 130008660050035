import React from 'react'
import ReactDom from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './modal.css'

export default function Modal({open, close, fadeIn, covered, children}) {
    if(!open) return null
    return ReactDom.createPortal(
        <div id="modal">
            <div className={covered ? "overlay covered" : "overlay"} onClick={close}></div>
            <div className={fadeIn ? "modal fade-in": "modal"}>
                <FontAwesomeIcon id="close-modal" icon="fa-solid fa-circle-xmark" onClick={close}/>
                {children}
            </div>
        </div>,
        document.getElementById('portal')
    )
}
