import React from 'react'
import {Routes, Route} from 'react-router-dom'
import './body.scss'

import GamePage from './non-auth/gamePage/GamePage'

export default function Body() {
    return (
        <Routes>
            <Route exact path="/" element={<GamePage />} />
            <Route exact path="/yesterday" element={<GamePage />} />
        </Routes>
    )
}
