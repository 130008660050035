import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'

import Loader from '../../utils/loader/Loader'

const proxy = 'https://motuz.fr'

function Letter({letterIdx, rowIdx, letterBg, letterBoxOutline}) {
    return (
        <div className='letter-container' style={{backgroundColor: letterBg, outline: `2px solid ${letterBoxOutline}`}}>
            <p id={`${rowIdx}${letterIdx}`}></p>
        </div>
    )
}

function Row({row, idx, letterBg, letterBoxOutline}) {
    return (
        <div className='row-container'>
            {row.map(letterIdx => <Letter key={letterIdx} letterIdx={letterIdx} rowIdx={idx} letterBg={letterBg} letterBoxOutline={letterBoxOutline}/>)}
        </div>
    )
}

function Board({letterBg, letterBoxOutline, structure}) {
    return (
    <div id='board'>
        {structure?.map((row, idx) => 
            <Row key={idx} row={row} idx={idx} letterBg={letterBg} letterBoxOutline={letterBoxOutline}/>
        )}
    </div>)
}

function Banner ({type, banners=[]}) {
    const activeBanners = banners.filter(el => el.isActive)
    let banner = [];
    const rnd = Math.floor(Math.random()*(activeBanners.length))
    banner = activeBanners[rnd]

    return (
        <a className='banner-link' href={banner?.url} rel="noopener noreferrer" target="_blank">
            {type === 'text' ? <p className='text-link'>{banner?.message}</p> : <img className='image-link' src={banner?.image} alt='banner'/>}
        </a>
    )
}

/* Game component */
const gameParameters = {
    rowsPerBoard: 6
}

export default function Game({subdomain, changeColors, changeExplainer}) {
    const [yesterdayWord, setYesterdayWord] = useState(null)
    const [structure, setStructure] = useState(null)
    const [game, setGame] = useState(null)


    // Loading
    const [loadingGame, setLoadingGame] = useState(true)
    const [loadingYesterdayWord, setLoadingYesterdayWord] = useState(true)

    useEffect(() => {
        const getYesterdayWord = async(date) => {
            try {
                const res = await axios.get(`${proxy}/data/games/yesterday-word/by-query?subdomain=${subdomain}&year=${date.getFullYear()}&month=${date.getMonth()+1}&date=${date.getDate()}`)
                setYesterdayWord(res.data.word)
                setStructure(Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => Array.from(Array(res.data.word.length).keys())))
                setLoadingYesterdayWord(false)
            } catch (err) {
                err.response.data.msg &&
                alert(err.response.data.msg)
            }
        }
        getYesterdayWord(new Date())
    }, [subdomain])

    // Get game data
    useEffect(() => {
        const getGame = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/by-query?subdomain=${subdomain}`)
                setGame({...res.data.game, banners: res.data.banners})
                const {goodLetterBg: good, standardLetterBg: std, misplacedLetterBg:misplaced, explainer, name} = res.data.game
                changeColors({good, std, misplaced})
                changeExplainer(explainer)
                setLoadingGame(false)
            } catch (err) {
                console.log(err)
                setLoadingGame(false)
            }
        }
        if(subdomain) getGame()
    }, [subdomain])

    const writeCorrectLetters = (row, word) => {
        const el = document.getElementById(`${row}0`)
        if(el) el.innerHTML = word[0]
        word.split('').forEach((letter,idx) => {
            const el = document.getElementById(`${row}${idx}`)
            if(el) el.innerHTML = letter.toUpperCase()
        })
    }

    useEffect(() => {
        if(yesterdayWord && game) writeCorrectLetters(0, yesterdayWord)
    }, [loadingGame, loadingYesterdayWord])
    
    const yesterdayDate = new Date()
    yesterdayDate.setDate(yesterdayDate.getDate() - 1)

    return (
        (loadingGame && loadingYesterdayWord) ? <div className='loader-game'><Loader /></div>
        :
        <div id='game-wrapper' style={game?.pageBgPattern ? {backgroundImage: `url(${game?.pageBgPattern})`} : {backgroundColor: `${game?.pageBg}`}}>
            <div id='game'>
                <div className='game-logo-container'>
                    {game?.logo && <img src={game?.logo} alt='game-logo'/>}
                </div>
                <div className='top-banner'>
                <div id='claim-container' style={{fontSize: '1.5em'}}>Le {game?.name} du {yesterdayDate.toLocaleDateString('en-GB')}</div>
                </div>
                <div className='game-board-container'>
                    <Board letterBg={game?.standardLetterBg} letterBoxOutline={game?.letterCaseOutline} structure={structure}/>
                </div>
                <div className='bottom-banner'>
                    {game?.bottomBannerType !== 'none' && game && <Banner type={game?.bottomBannerType} banners={game?.banners} />}
                </div>
            </div>
        </div>
    )

}
