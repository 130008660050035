import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'

import Keyboard from '../../utils/keyboard/Keyboard'

import './game.scss'
import Loader from '../../utils/loader/Loader'

const proxy = 'https://motuz.fr'

function Letter({letterIdx, rowIdx, letterBg, letterBoxOutline}) {
    return (
        <div className='letter-container' style={{backgroundColor: letterBg, outline: `2px solid ${letterBoxOutline}`}}>
            <p id={`${rowIdx}${letterIdx}`}></p>
        </div>
    )
}

function Row({row, idx, letterBg, letterBoxOutline}) {
    return (
        <div className='row-container'>
            {row.map(letterIdx => <Letter key={letterIdx} letterIdx={letterIdx} rowIdx={idx} letterBg={letterBg} letterBoxOutline={letterBoxOutline}/>)}
        </div>
    )
}

function Board({letterBg, letterBoxOutline, structure}) {
    return (
    <div id='board'>
        {structure?.map((row, idx) => 
            <Row key={idx} row={row} idx={idx} letterBg={letterBg} letterBoxOutline={letterBoxOutline}/>
        )}
    </div>)
}

function Banner ({type, banners=[]}) {
    const activeBanners = banners.filter(el => el.isActive)
    let banner = [];
    const rnd = Math.floor(Math.random()*(activeBanners.length))
    banner = activeBanners[rnd]

    return (
        <a className='banner-link' href={banner?.url} rel="noopener noreferrer" target="_blank">
            {type === 'text' ? <p className='text-link'>{banner?.message}</p> : <img className='image-link' src={banner?.image} alt='banner'/>}
        </a>
    )
}

/* Game component */
const gameParameters = {
    rowsPerBoard: 6,
    soundsDelay: 300
}

export default function Game({subdomain, keyboard, updateLS, muted, changeColors, changeExplainer}) {
    const [current, setCurrent] = useState({row: 0, col: 1})
    const [word, setWord] = useState(null)
    const [structure, setStructure] = useState(null)
    const [game, setGame] = useState(null)
    const [wordsList, setWordsList] = useState([])
    const [guesses, setGuesses] = useState([])
    const [chosenKeys, setChosenKeys] = useState({correct: [], incorrect: [], misplaced: []})
    const [resultStatus, setResultStatus] = useState('')
    const [justFinished, setJustFinished] = useState(false)

    // Loading
    const [loadingWordsList, setLoadingWordsList] = useState(true)
    const [loadingGame, setLoadingGame] = useState(true)
    const [loadingWord, setLoadingWord] = useState(true)

    // Msg
    const [msg, setMsg] = useState(null)
    let timeoutRef = useRef(null)

    // Audio element reference
    let audioRef = useRef(new Audio())

    // Date
    const today = new Date()
    today.setHours(0,0,0,0)
    let nextMidnight = new Date();
    nextMidnight.setHours(24,0,0,0);

    //Block input
    let block = false

    const getWord = async(date) => {
        try {
            return await axios.get(`${proxy}/data/games/word/by-query?subdomain=${subdomain}&year=${date.getFullYear()}&month=${date.getMonth()+1}&date=${date.getDate()}`)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
        }
    }

    useEffect(() => {
        const motuzLS = {...JSON.parse(window.localStorage.getItem("motuzState"))}
        let currentLaunchDate;
        if(motuzLS) {
            const gameLS = motuzLS[subdomain]
            if(gameLS) {
                // Already visited
                currentLaunchDate = gameLS ? new Date(gameLS.current.launchDate) : null
                currentLaunchDate.setHours(0,0,0,0)
                if(currentLaunchDate && (today > currentLaunchDate)){
                    console.log('new day detected')
                    getWord(today).then(res => {
                        gameLS.current = {
                            word: res.data.word,
                            launchDate: today,
                            guesses: [],
                            chosenKeys: {correct: [], incorrect: [], misplaced: []},
                            resultStatus: ''
                        }
                        window.localStorage.setItem("motuzState", JSON.stringify({...motuzLS,
                            [subdomain] : {...gameLS}
                        }))
                        setWord(res.data.word)
                        setStructure(Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => Array.from(Array(res.data.word.length).keys())))
                        setLoadingWord(!loadingWord)
                        updateLS()
                        writeCorrectLetters(0, res.data.word)
                    })
                } else {
                    console.log('day has not finished')
                    const {word, guesses, chosenKeys, resultStatus} = gameLS.current
                    setWord(word)
                    setStructure(Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => Array.from(Array(word?.length).keys())))
                    setGuesses(guesses)
                    setChosenKeys(chosenKeys)
                    setCurrent({row: guesses.length, col: 1})
                    setResultStatus(resultStatus)
                    setLoadingWord(!loadingWord)
                    writeCorrectLetters(guesses.length, word)
                }
            } else {
                console.log('first game visit')
                // First visit to the game
                getWord(today).then(res => {
                    window.localStorage.setItem("motuzState", JSON.stringify({...motuzLS,
                        [subdomain] : {
                            name: res.data.name,
                            shareMsg: res.data.shareMsg,
                            games: 0,
                            tries: Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => 0),
                            totalTries: 0,
                            avg: 0,
                            losses: 0,
                            current: {
                                word: res.data.word,
                                launchDate: today,
                                guesses: [],
                                chosenKeys: {correct: [], incorrect: [], misplaced: []},
                                resultStatus: ''
                            }
                        }
                    }))
                    setWord(res.data.word)
                    setStructure(Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => Array.from(Array(res.data.word.length).keys())))
                    setLoadingWord(!loadingWord)
                    writeCorrectLetters(0, res.data.word)
                })
            }
        } else {
            console.log('first portal visit')
            // First visit to the portal
            getWord(today).then(res => {
                window.localStorage.setItem("motuzState", JSON.stringify({
                    [subdomain] : {
                        name: res.data.name,
                        shareMsg: res.data.shareMsg,
                        games: 0,
                        tries: Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => 0),
                        totalTries: 0,
                        avg: 0,
                        losses: 0,
                        current: {
                            word: res.data.word,
                            launchDate: today,
                            guesses: [],
                            chosenKeys: {correct: [], incorrect: [], misplaced: []},
                            resultStatus: ''
                        }
                    }
                }))
                setWord(res.data.word)
                setStructure(Array.from(Array(gameParameters.rowsPerBoard).keys()).map(el => Array.from(Array(res.data.word.length).keys())))
                setLoadingWord(!loadingWord)
                writeCorrectLetters(0, res.data.word)
            })
        }
    }, [])

    // Get game data
    useEffect(() => {
        const getGame = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/by-query?subdomain=${subdomain}`)
                setGame({...res.data.game, banners: res.data.banners})
                const {goodLetterBg: good, standardLetterBg: std, misplacedLetterBg:misplaced, explainer, name} = res.data.game
                changeColors({good, std, misplaced})
                changeExplainer(explainer)
                setLoadingGame(false)
            } catch (err) {
                console.log(err)
                setLoadingGame(false)
            }
        }
        if(subdomain) getGame()
    }, [subdomain])

    // Get game words list
    useEffect(() => {
        const getWordsList = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/words-list/by-query?id=${game._id}&word=${word}`)
                setWordsList(res.data.wordsList)
                setLoadingWordsList(false)
            } catch (err) {
                err.response.data.msg &&
                alert(err.response.data.msg)
                setLoadingWordsList(false)
            }
        }
        if(word && game) getWordsList()
    }, [word, game])

    useEffect(() => {
        const updateStats = () => {
            axios.get('https://api.ipify.org')
            .then(res => {
                axios.put(`${proxy}/data/visitors/update-game-stats`,{ip: res.data, subdomain})
                .then(res => console.log(res.data))
                .catch(err => err.response.data.msg && console.log(err.response.data.msg))
            })
            .catch(err => {
                console.log(err);
            });
        }
        updateStats()
        setInterval(() => {
            updateStats()
        }, 1000*20);
    }, [])

    const restorePreviousGuesses = guesses => {
        guesses.forEach(({guess, results}, row) => {
            guess.split('').forEach((letter, col) => {
                const el = document.getElementById(`${row}${col}`)
                el.innerHTML = letter.toUpperCase()
                switch (results[col]) {
                    case 'correct':
                        el.style.backgroundColor = game?.goodLetterBg
                        break;
                    case 'incorrect':
                        el.style.backgroundColor = game?.standardLetterBg
                        break;
                    case 'misplaced':
                        el.classList.add('misplaced')
                        el.style.backgroundColor = game?.misplacedLetterBg
                        break;
                    default:
                        break;
                }
            })
        })
    }

    useEffect(() => {
        restorePreviousGuesses(guesses)
    }, [guesses, game])

    
    useEffect(() => {
        if(justFinished) {
            console.log('finished')
            saveStatsLocalStorage()
            updateLS()
            if(guesses.length < 5) {
                const el = document.getElementById(`${guesses.length}0`)
                el.innerHTML = ''
            }
        }
    }, [justFinished])

    useEffect(() => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        if(ls && resultStatus === '') writeCorrectLetters(current.row, word)
    }, [word, current.row])
    
    
    // Key Up event listener, only available while playing
    useEffect(() => {
        if(resultStatus === '') {
            document.addEventListener("keydown", handleKeyUp);
            return () => {
                document.removeEventListener('keydown', handleKeyUp);
            }
        } else {
            // Game is finished
            document.removeEventListener('keydown', handleKeyUp);
        }
    }, [current.row, current.col, resultStatus]);

    useEffect(()=> {
        if(msg){
            document.getElementById('msg').style.opacity = '1'
            timeoutRef.current = setTimeout(() => {
                document.getElementById('msg').style.opacity = '0'
                setTimeout(()=>{
                    setMsg('')
                    document.getElementById('claim-container').style.opacity = '1'
                }, 1000)
            }, 5000);
        }
    }, [msg])

    useEffect(() => {
        audioRef.current.muted = muted
    }, [muted])

    const writeCorrectLetters = (row, word) => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        const {guesses} = ls[subdomain].current
        const el = document.getElementById(`${row}0`)
        if(el) el.innerHTML = word[0]
        guesses.forEach(guess => {
            guess.results.forEach((result,idx) => {
                if(result !== 'correct') return
                const el = document.getElementById(`${row}${idx}`)
                if(el) el.innerHTML = word[idx]
            })
        })
    }

    const eraseCorrectLetters = (row) => {
        for(let i = 2; i < word.length; i++) {
            const el = document.getElementById(`${row}${i}`)
            if(el) el.innerHTML = ''
        }
    }

    // Key up handler function
    const handleKeyUp = (e) => {
        e.preventDefault()
        if(resultStatus !== '' || block) return
        clearTimeout(timeoutRef.current)
        setMsg('')
        if(e.code === "Enter") {
            handleEnter()
        }
        if(e.key.match(/^[a-zA-Z]$/)){
            handleLetterInput(e.key)  
        }
        if(e.code === "Backspace") {
            handleBackspace()
        }
    }
    
    // Keyboard Input handler function
    const handleKeyboardInput = e => {
        if(resultStatus !== '' || block) return
        clearTimeout(timeoutRef.current)
        setMsg('')
        const {innerHTML: key} = e.target
        e.target.style.opacity = "0.5"
        e.target.style.top = '2px'
        setTimeout(() => {
            e.target.style.removeProperty('opacity')
            e.target.style.top = '0px'
        }, 200);

        if(key === "↲") {
            handleEnter()
        }
        if(key.match(/^[a-zA-Z]$/)){
            handleLetterInput(key)  
        }
        if(key === "⌫") {
            handleBackspace()
        }
    }

    // Detects an letter input
    const handleLetterInput = (key) => {
        const motuzLS = {...JSON.parse(window.localStorage.getItem("motuzState"))}
        const {word} = motuzLS[subdomain].current
        if(current.col === 1 && current.row > 0) eraseCorrectLetters(current.row)
        if(current.col < word.length) {
            document.getElementById(`${current.row}${current.col}`).style.backgroundColor = game?.standardLetterBg
            document.getElementById(`${current.row}${current.col}`).innerHTML = key.toUpperCase()
            setCurrent({...current, col: current.col + 1})
        }
    }

    // Detects a backspace key up
    const handleBackspace = () => {
        if(current.col > 1) {
            document.getElementById(`${current.row}${current.col - 1}`).innerHTML = ''
            return setCurrent({...current, col: current.col - 1})
        }
        writeCorrectLetters(current.row, word)
    }

    const saveCurrentInLocalStorage = (guess, chosen) => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        const {guesses} = ls[subdomain].current
        guesses.push(guess)
        ls[subdomain].current.guesses = guesses
        ls[subdomain].current.chosenKeys = chosen
        window.localStorage.setItem("motuzState", JSON.stringify(ls))
    }

    
    const saveStatsLocalStorage = () => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        const gameLS = ls[subdomain]
        gameLS.current.resultStatus = resultStatus
        gameLS.games += 1

        if(resultStatus === 'win'){
            const finishedGameTries = guesses.length
            gameLS.tries[finishedGameTries - 1] += 1
            gameLS.totalTries += finishedGameTries // Adds the number of tries of the current game
        } else {
            gameLS.current.won = false
            gameLS.losses += 1
            gameLS.totalTries += gameParameters.rowsPerBoard // A loss adds 6 tries to the accumulator
        }
        gameLS.avg = (gameLS.totalTries/ gameLS.games).toFixed(2)
        window.localStorage.setItem("motuzState", JSON.stringify({...ls, [subdomain]:gameLS}))
    }

    // Detect an "Enter" key up
    const handleEnter = (e) => {
        if(current.col !== word?.length) return displayMsg(0)
        block = true
        document.removeEventListener('keydown', handleKeyUp);
        const guess = structure[current.row].map(letterIdx => document.getElementById(`${current.row}${letterIdx}`).innerHTML.toUpperCase()).join('')
        isWordInTheList(guess).then(res => {
            if(!res) {
                document.addEventListener("keydown", handleKeyUp);
                block = false
                return displayMsg(1)
            }
            const {results, chosen} = evaluateLetters(guess)
            revealLetters(results, 0)
            let i = 1
            const interval = setInterval(() => {
                revealLetters(results, i)
                i++
                if(i === word.length){
                    setGuesses([...guesses, {guess, results}])
                    setChosenKeys(chosen)
                    saveCurrentInLocalStorage({guess,results}, chosen)

                    if(guess === word.toUpperCase()){
                        setTimeout(() => {
                            audioRef.current.src = game?.winSound
                            const mute = document.getElementById("mute")
                            audioRef.current.muted = mute ? true : false
                            audioRef.current.play()
                        }, 800);
                        setResultStatus('win')
                        setJustFinished(true)
                    } else {
                        console.log('evaluating if finished')
                        if(current.row < gameParameters.rowsPerBoard - 1) {
                            writeCorrectLetters(current.row + 1, word)
                            setCurrent({col: 1, row: current.row + 1})
                        } else {
                            setTimeout(() => {
                                audioRef.current.src = game?.lossSound
                                const mute = document.getElementById("mute")
                                audioRef.current.muted = mute ? true : false
                                audioRef.current.play()
                            }, 800);
                            setResultStatus('loss')
                            setJustFinished(true)
                        }
                    }
                    block = false
                    return clearInterval(interval)
                }
            }, gameParameters.soundsDelay);
        })
    }

    // Evaluates whether or not the word is included in the game word list
    const isWordInTheList = async(guess) => {
        // try {
        //     const res = await axios.get(`${proxy}/data/games/validate-word/by-query?id=${game._id}&word=${guess}`)
        //     return res.data.isValid
        // } catch (err) {
        //     err.response.data.msg &&
        //     alert(err.response.data.msg)
        // }
        return wordsList.includes(guess)
    }

    const evaluateLetters = (guess) => {
        const wordInRevision = word.split('').map(el => String(el).toUpperCase())
        const guessToEvaluate = guess.split('').map(el => String(el).toUpperCase())
        const rowResults = new Array(word?.length)
        const correct = []
        const incorrect = []
        const misplaced = []
        const correctChecked = guessToEvaluate.map((letter,idx) => {
            if(letter === wordInRevision[idx]){
                if(!correct.includes(letter) && !chosenKeys.correct.includes(letter)) correct.push(letter)
                rowResults[idx] = 'correct'
                wordInRevision[idx] = ''
                return ''
            }
            return letter
        })

        const misplacedChecked = correctChecked.map((letter,idx) => {
            if(letter === '') return ''
            const indexOfFoundLetter = wordInRevision.findIndex(el => el === letter)
            if(indexOfFoundLetter + 1){
                if(!misplaced.includes(letter) && !correct.includes(letter) && !chosenKeys.misplaced.includes(letter) && !chosenKeys.correct.includes(letter)) misplaced.push(letter)
                rowResults[idx] = 'misplaced'
                wordInRevision[indexOfFoundLetter] = ''
                return ''
            }
            return letter
        })

        misplacedChecked.forEach((letter, idx) => {
            if(letter === '') return ''
            if(!incorrect.includes(letter) && !misplaced.includes(letter) && !correct.includes(letter) && !chosenKeys.incorrect.includes(letter) && !chosenKeys.misplaced.includes(letter) && !chosenKeys.correct.includes(letter)) incorrect.push(letter)
            rowResults[idx] = 'incorrect'
        })
        const chosen = {correct: [...chosenKeys.correct, ...correct], incorrect: [...chosenKeys.incorrect, ...incorrect], misplaced: [...chosenKeys.misplaced, ...misplaced]}
        return {results: rowResults, chosen}
    }

    // Change the classes according to evaluation of each letter in the guess
    const revealLetters = (results, i) => {
        const mute = document.getElementById("mute")
        audioRef.current.muted = mute ? true : false
        switch (results[i]) {
            case 'correct':
                document.getElementById(`${current.row}${i}`).style.backgroundColor = game?.goodLetterBg
                audioRef.current.src = game?.goodLetterSound
                break;
            case 'incorrect':
                document.getElementById(`${current.row}${i}`).style.backgroundColor = game?.standardLetterBg
                audioRef.current.src = game?.wrongLetterSound
                break;
            case 'misplaced':
                document.getElementById(`${current.row}${i}`).classList.add('misplaced')
                document.getElementById(`${current.row}${i}`).style.backgroundColor = game?.misplacedLetterBg
                audioRef.current.src = game?.misplacedLetterSound
                break;
            default:
                break;
        }
        audioRef.current.muted = mute ? true : false
        audioRef.current.play()
    }

    // Displays message on top of the board
    const displayMsg = (idx) => {
        switch (idx) {
            case 0:
                return setMsg('Mot trop court')
            case 1:
                return setMsg('Mot n\'est pas dans notre dictionnaire')
            default:
                break;
        }
    }
    return (
        (loadingGame && loadingWord && loadingWordsList) ? <div className='loader-game'><Loader /></div>
        :
        <div id='game-wrapper' style={game?.pageBgPattern ? {backgroundImage: `url(${game?.pageBgPattern})`} : {backgroundColor: `${game?.pageBg}`}}>
            <div id='game'>
                <div className='game-logo-container'>
                    {game?.logo && <img src={game?.logo} alt='game-logo'/>}
                </div>
                <div className='top-banner'>
                    {msg ? <div id='msg'>{msg}</div>
                    : game?.topBannerType !== 'none'? game && <Banner type={game?.topBannerType} banners={game?.banners} /> : <div id='claim-container'>{game?.ingameClaim}</div>}
                </div>
                <div className='game-board-container'>
                    <Board letterBg={game?.standardLetterBg} letterBoxOutline={game?.letterCaseOutline} structure={structure}/>
                </div>
                <div className='keyboard-container'>
                    <Keyboard 
                        keyboard={keyboard} 
                        getLetter={handleKeyboardInput} 
                        chosen={chosenKeys}
                        colors={{std:game?.standardLetterBg, good:game?.goodLetterBg, misplaced: game?.misplacedLetterBg}} />
                </div>
                <div className='bottom-banner'>
                    {game?.bottomBannerType !== 'none' && game && <Banner type={game?.bottomBannerType} banners={game?.banners} />}
                </div>
            </div>
        </div>
    )

}
